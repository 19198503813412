<template>
    <div class="modal" v-if="modelValue" :class="{'active' : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Product <span>Share with Locations & Coaches</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form class="forms-setting-form" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="share-product-with-account-form">
                    <div class="setting_wpr" >
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Secondary Locations</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.locations }">
                                    <Field autocomplete="off" name="locations" v-model="form.locations" :class="{ 'has-error': errors.locations }" :rules="{ required: form.coaches.length == 0 }">
                                        <multiselect
                                            v-model="form.locations"
                                            :options="organizationLocations"
                                            value-prop="user_id"
                                            label="company_name"
                                            :searchable="true"
                                            placeholder="Select locations"
                                            mode="tags"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="locations" class="text-danger" />
                            </div>
                        </div>
                        <h3 class="hr-line"><span>OR</span></h3>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Coaches</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.coaches }">
                                    <Field autocomplete="off" name="coaches" v-model="form.coaches" :class="{ 'has-error': errors.coaches }" :rules="{ required: form.locations.length == 0 }">
                                        <multiselect
                                            v-model="form.coaches"
                                            :options="organizationUsers"
                                            value-prop="id"
                                            label="full_name"
                                            :searchable="true"
                                            placeholder="Select coaches"
                                            mode="tags"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="coaches" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" :disabled="loader" type="button" @click="closeModal()">Cancel</button>
                <button class="btn save_btn" :disabled="loader" type="button" @click="handleSubmit()"><i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Sharing' : 'Share'}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Share Product With Account',

        data () {
            return {
                form: {
                    id: '',
                    locations: [],
                    coaches: [],
                },
            }
        },

        props: {
            modelValue: Boolean,
            selectedProduct: {
                type: Object,
                default: () => {},
            },
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.resetForm();

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            }
        },

        computed: mapState({
            loader: state => state.checkoutModule.checkoutLoader,
            organizationUsers: state => state.authModule.organizationUsers,
            organizationLocations: state => state.authModule.organizationLocations,
        }),

        mounted () {
            const vm = this;

            if (vm.organizationLocations.length == 0) {
                vm.getOrganizationLocations();
            }

            if (vm.organizationUsers.length == 0) {
                vm.getOrganizationUsers();
            }
        },

        methods: {
            ...mapActions({
                getOrganizationUsers: 'authModule/getOrganizationUsers',
                getOrganizationLocations: 'authModule/getOrganizationLocations',
                shareProductWithAccounts: 'checkoutModule/shareProductWithAccounts',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmit () {
                const vm = this;

                const form = vm.$refs['share-product-with-account-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.loader = true;

                        vm.shareProductWithAccounts(vm.form).then((result) => {
                            if (result) {
                                vm.closeModal();
                            }

                            vm.loader = false;
                        });
                    }
                });
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    id: vm.selectedProduct.id,
                    locations: [],
                    coaches: [],
                };
            }
        }
    }
</script>

<style scoped>
    h3.hr-line {
        width: 100%;
        text-align: center; 
        border-bottom: 1px solid #CBCDD3; 
        line-height: 0.1em;
        margin: 20px 0 20px; 
        color: #121525;
    } 

    h3.hr-line span { 
        background: #fafafb; 
        padding: 0 10px; 
    }
</style>